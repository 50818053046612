import React from "react";
import { Col, Row, Button, Card, CardColumns, CardImg } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { SRLWrapper } from "simple-react-lightbox";


// styles
const Gallerytitle = styled.h2`
color: ${({ theme }) => theme.colours.primary.main};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 1.4rem;
  text-align: center;
`

const Gallerycontainer = styled.div`
  background-color: ${({ theme }) => theme.colours.common.white};
  padding: 25px 30px 40px 30px;
  margin-bottom: 15px;
`


const GalleryDescription = styled.div`
  color: ${({ theme }) => theme.colours.common.black};
  min-height: 250px;
  text-align: left;

  p {
    line-height: 1.2;
  }

  & a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
    transition: ${({ theme }) => theme.animations.link};
  }
  
  & a:hover {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: bold;
  }
  
`

const Serviceimg = styled(GatsbyImage)`
  margin: 0 auto;
`

const Servicerow = styled(Row)`
  margin-bottom: 50px;
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #ba2117;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;
  text-align: center;

  &:hover, :active, .active, .focus, :focus, :not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus, :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`

const Learnlink = styled(Link)`
  display: block;
  text-align: center;
`

function Gallerylist({ children }) {
 
  const data = useStaticQuery(graphql`
  {
    directus {
      gallery {
        title
        gallery_image {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            publicURL
          }
        }
      }
    }
  }  
  `)

  const galleryNode = data.directus.gallery;
 
  return (
    
    <>
    {/* <SRLWrapper>
        <Servicerow className="gallery">
        {galleryNode.map((galleryNode, i) => 
        (
          
            <Col xs={12} md={6} lg={4} key={i} className="px-0 gallery-list">
              <div class="image-grid">
              <Link to={galleryNode.gallery_image.imageFile.publicURL}><Serviceimg image={galleryNode.gallery_image.imageFile.childImageSharp.gatsbyImageData} alt={galleryNode.title} objectPosition="center center" srl_gallery_image="true" /></Link>
              </div>
            </Col>
             ))}    
        </Servicerow>
    </SRLWrapper> */}
    
    <SRLWrapper>
    <Row>
    
    {galleryNode.map((galleryNode, i) => 
        (
      <Col sm={4}>
      <Card>
        <Link to={galleryNode.gallery_image.imageFile.publicURL}><Serviceimg image={galleryNode.gallery_image.imageFile.childImageSharp.gatsbyImageData} alt={galleryNode.title} objectPosition="center center" srl_gallery_image="true" className="card-img" /></Link>
      </Card>
      </Col>
             ))}    
    </Row>
    </SRLWrapper>
  </>
  );
}

export default Gallerylist;