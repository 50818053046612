import React from "react"
import Pagelayout from "../layouts/page"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Col, Row, Container, Button} from "react-bootstrap"
import styled from "styled-components"
import Gallerylist from "../components/gallerylist"

// styles
const Pageheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 20px auto;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};

  @media (min-width: 992px) { 
    margin: 10px 0 50px 0;
  }
`

function Gallerypage ({ data }) {

  return (
    <>
    <Seo title={`${data.directus.settings.site_title} · ${data.directus.home.seo_title}`} description={data.directus.settings.site_description} author={data.directus.settings.site_author}  />
    <Pagelayout logo={data.directus.settings.site_logo} footerimage={data.directus.settings.footer_image} >
        <Row>
          <Col>
                <Pageheading>Gallery</Pageheading>
          </Col>
        </Row>
        <Gallerylist />
    </Pagelayout>
    </>
  )
}


export default Gallerypage

export const query = graphql`
query {
  directus {
    settings {
      site_title
      site_description
      site_author
      site_logo {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      footer_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      contact_details
    }
    home {
      seo_title
      hero_title
      hero_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      hero_subtitle
      about_heading
      about_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      accreditation
      professionalism
      projects_heading
      projects_summary
      training
      projects_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
}
`